import cellGreen from './assets/cellGreen.png';
import cellYellow from './assets/cellYellow.png';
import cellMissing from './assets/cellMissing.png';

export const BullLetterStyle = {
  backgroundSize: "cover",
  backgroundImage: `url(${cellGreen})`,
  color: "var(--cell-color)",
};

export const CowLetterStyle = {
  backgroundSize: "cover",
  backgroundImage: `url(${cellYellow})`,
  color: "var(--cell-color)",
};

export const MissedLetterStyle = {
  backgroundSize: "cover",
  backgroundImage: `url(${cellMissing})`,
  color: "var(--cell-color-missed)",
};