import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import {
  linkWallet,
  selectWeb3State,
  claimReward,
} from "./features/web3/web3slice";

export const ClaimFlow = (props: { day: number, attempts: number, marks: string[] }) => {
  const dispatch = useAppDispatch();
  const web3State = useAppSelector(selectWeb3State);

  const [claimProgressWord, setClaimProgressWord] = useState('Claiming...')
  const [fill, setFill] = useState(0)

  useEffect(() => {
    // artificial progress... 
    if(web3State.claimingInProgress) {
      setFill(8);
      setClaimProgressWord('')
      setTimeout(() => {
        setFill(30);
        setClaimProgressWord('Rendering your NFT..');
      }, 2000)

      setTimeout(() => {
        setFill(50);
        setClaimProgressWord('Writing to blockchain..');
      }, 4500)

      setTimeout(() => {
        setFill(90);
        setClaimProgressWord('Transfering NFT..');
      }, 6500)
    } else {
      setFill(0) 
    }

  }, [web3State.claimingInProgress])

  return (
    <div>
      {web3State.error && <p style={{ color: "red" }}>{web3State.error}</p>}
      {!web3State.claimed && (
        <>
          {web3State.walletAddress && !web3State.claimed ? (
            <>
              <button
                style={{position: 'relative'}}
                disabled={web3State.claimingInProgress}
                onClick={() => dispatch(claimReward({ day: props.day, attempts: props.attempts, marks: props.marks }))}
              >
                {web3State.claimingInProgress ? 'Claiming' : "Claim"}
                {web3State.claimingInProgress && <div className="claim-progress" style={{width: `${fill}%`}}></div>}
              </button>
              {web3State.claimingInProgress && <p>{claimProgressWord}</p>}
            </>
          ) : (
            <button onClick={() => dispatch(linkWallet())}>Link wallet</button>
          )}
        </>
      )}
      {web3State.claimed && <h3>NFT claimed! <a href={web3State.claimedTokenUrl} target="_blank">View on IMX</a></h3>}
    </div>
  );
};

export default ClaimFlow;
