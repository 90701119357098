import { State } from "../storage/daily"
import { GameState } from "../features/game/gameSlice"
import * as playpass from 'playpass';

export function onShareClick(
  s: State,
  l: GameState['levelState'],
  st: 'WIN' | 'LOSS' | 'LOADING' | 'PENDING',
) {

  // Create a link to our game
  const link = playpass.createLink()

  if (st === 'LOADING' || st === 'PENDING') {
    const text =
      '3dle #3dle #NFT \n' +
      'gm gm! Can you guess today\'s word?'
      + '\n\n' +
      link
   return playpass.share({text});
  }

  // Share some text along with our link
  const text =
    '3dle' +
    ' #' +
    s.day +
    ' ' +
    (st === 'LOSS' ? 'X' : s.marks.length.toString()) +
    '/' +
    l.guesses +
    '\n\n' +
    s.marks
      .map((str) =>
        str.replace(/n/g, '⬜').replace(/b/g, '🟩').replace(/c/g, '🟨'),
      )
      .join('\n') +
    '\n\n' + link + ' #3dle #NFT'

  return playpass.share({ text })
}
