import classNames from 'classnames'
import gsap from 'gsap'
import { useEffect, useRef } from 'react'

type BannerProps = {
  text?: string
  type: 'info' | 'reveal' | 'none'
  transient?: boolean
  onInfoDismissed?: () => void
}

const BANNER_HEIGHT = '44px'

const Banner = (props: BannerProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const { text, type, transient } = props

  useEffect(() => {
    if (type === 'none') {
      return
    }

    if (type === 'info') {
      const tl = gsap.timeline({})
      tl.to(ref.current, {
        duration: 0.5,
        height: BANNER_HEIGHT,
        ease: 'strong.inOut',
      })
      tl.to(ref.current, {
        delay: 3.0,
        height: '0px',
        ease: 'strong.outIn',
      })
      tl.then(() => {
        props.onInfoDismissed && props.onInfoDismissed()
      })

      return () => {
        tl.kill()
      }
    }

    if (type === 'reveal') {
      const animation = gsap.to(ref.current, {
        duration: 0.5,
        height: BANNER_HEIGHT,
        ease: 'strong.inOut',
      })
      return () => animation.kill()
    }
  }, [text, type])

  const classes = classNames({
    info: props.type === 'info',
    reveal: props.type === 'reveal',
    banner: true,
  })

  return (
    <div className="bannerContainer">
      <div className={classes} ref={ref}>
        <span>{text}</span>
      </div>
    </div>
  )
}
export default Banner
