import classNames from 'classnames'
import { useEffect } from 'react'
import { State } from '../storage/daily'
import { GridState, GuessClasses, GuessSymbols } from './Grid'

const Keyboard = (props: { state: State; onKey: (key: string) => void }) => {
  const Row1 = ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P']
  const Row2 = ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L']
  const Row3 = ['Delete', 'Z', 'X', 'C', 'V', 'B', 'N', 'M', 'Enter']

  const { words, marks } = props.state

  const charStates = Array.from(Array(26), () => GridState.EMPTY)
  const ccA = 'A'.charCodeAt(0)

  for (let w = 0; w < marks.length; w++) {
    for (let i = 0; i < words[w].length; i++) {
      const c = words[w].charCodeAt(i) - ccA
      const newMarkPriority = GuessSymbols.indexOf(marks[w][i])

      if (newMarkPriority > GuessSymbols.indexOf(charStates[c])) {
        charStates[c] = marks[w][i] as GridState
      }
    }
  }

  const MapKeys = (keys: string[]) => {
    return keys.map((e) => {
      const charIndex = e.charCodeAt(0) - ccA
      const classes = classNames(
        {
          enter: e === 'Enter',
          delete: e === 'Delete',
        },
        e.length === 1
          ? GuessClasses[GuessSymbols.indexOf(charStates[charIndex])]
          : '',
      )
      return (
        <div
          key={e}
          className={classes}
          onPointerUp={() => {
            props.onKey(e)
          }}
          onClick={() => {}}
        >
          {e}
        </div>
      )
    })
  }

  useEffect(() => {
    const subscribeKeyboard = (event: KeyboardEvent) => {
      let key
      switch (event.key) {
        case 'Enter':
          key = 'Enter'
          break
        case 'Backspace':
        case 'Delete':
          key = 'Delete'
          break
        default:
          if (event.key.length == 1) {
            const charCode = event.key.toUpperCase().charCodeAt(0)
            if (charCode >= 65 && charCode <= 90) {
              key = String.fromCharCode(charCode)
            }
          }
      }
      if (key) {
        props.onKey(key)
      }
    }

    window.addEventListener('keydown', subscribeKeyboard)
    return () => window.removeEventListener('keydown', subscribeKeyboard)
  })

  return (
    <div className="keyboard">
      <div>{MapKeys(Row1)}</div>
      <div>{MapKeys(Row2)}</div>
      <div>{MapKeys(Row3)}</div>
    </div>
  )
}

export default Keyboard
