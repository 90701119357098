const PRINTER_ENDPOINT = process.env.REACT_APP_PRINTER_ENDPOINT

//const PRINTER_ENDPOINT =
// "http://localhost:3001";

// helper fetch function with defaults for printer api
async function printerFetch<ReturnType>(
  path: string,
  request?: {
    method?: "GET" | "POST";
    body?: Record<string, any>;
    headers?: { [key: string]: string };
  }
): Promise<ReturnType> {
  return fetch(`${PRINTER_ENDPOINT}${path}`, {
    ...request,
    headers: {
      ...(request?.headers ?? {}),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(request?.body),
  }).then(async (r) => {
    if (r.ok) {
      return r.json();
    } else {
      const b = await r.text();
      if (b.includes("Token already claimed")) {
        throw new Error("Token already claimed");
      }
      throw new Error(`Request Failed ${r.status}: ${b}`);
    }
  });
}

export function getLevel() {
  return printerFetch<{ word: string }>(
    `/challenge?offset=${encodeURIComponent(new Date().getTimezoneOffset())}`,
  );
}

// TODO: share type between printer backend and this api lib
type HistoryResult = {
  day: number;
  word: string;

  marks?: string[]
  tokenId?: string
  nftURL?: string
}

export function getHistory(wallet: string): Promise<{history: HistoryResult[]}>{
  const offset = new Date().getTimezoneOffset();
  return printerFetch(
    `/history/${wallet}?offset=${encodeURIComponent(offset)}`,
    {}
  );
}

export function claimReward(props: {
  walletAddress: string;
  day: number;
  challengeMeta: {
    marks: string[];
    attempts: number;
  };
}): Promise<{uri: string}> {
  const offset = new Date().getTimezoneOffset();
  return printerFetch<{uri: string}>("/challenge", {
    method: "POST",
    body: {
      offset,
      ...props
    },
  });
}
