import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { Middleware, Action } from "redux";
import { RootState, store } from "./app/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import * as playpass from "playpass";
import { GUESS_LENGTHS, START_TIME } from "./config/game";
import "./index.css";
import { Daily } from "./storage/daily";
import {
  addLetter,
  loadLevel,
  login,
  logout,
  needsTutorial,
  removeLetter,
  resetNuxState,
  resetState,
  resetUserSettings,
  setNuxState,
  shareLevel,
  submitAnswer,
} from "./features/game/gameSlice";
import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit";
import { addAppListener, startAppListening } from "./app/middleware";
import GameLogic from "./GameLogic";
import { initGCInstant } from "playpass/dist/esm/gcinstant";

import { handleVisibilityChange } from "./util/pageVisibility";
import { NuxStorage } from "./storage/nux";
import { Web3Storage } from "./storage/web3";
import { loadHistory } from "./features/history/historySlice";
import { claimReward, linkWallet, loadWeb3State } from "./features/web3/web3slice";
import { BrowserRouter } from "react-router-dom";
import { UserSettingsStorage } from './storage/usersettings'
import { initGTM } from 'playpass/dist/esm/gtm'

let d: Daily = new Daily();
let web3Storage: Web3Storage = new Web3Storage();
let nuxStorage: NuxStorage = new NuxStorage();
let timerForTomorrow: ReturnType<typeof setTimeout> | undefined;
let userStorage = new UserSettingsStorage()

const reload = async () => {
  store.dispatch(loadLevel());
};

handleVisibilityChange((hidden) => {
  console.log("visibility change");
  !hidden && reload();
});

const container = document.getElementById("root")!;
const root = createRoot(container);
const setup = async () => {
  initGTM({ tagId: 'GTM-TM6B4ZN' })
  await playpass.init({
    gameId: "46eff6ea-bb95-4915-9c17-77430c04ee50",
  });

  await initGCInstant({
    amplitude: "dcbb6c28b27aa82df113d3b76428b3a7",
  });

  store.dispatch(
    addAppListener({
      matcher: isAnyOf(loadLevel.fulfilled),
      effect: async (action, listenerApi) => {
        const currentLevel = listenerApi.getState().game.levelState;
        d.resetDay(currentLevel.day);

        const s = await d.loadObject();
        //const history = await historyStorage.loadObject()
        const web3 = await web3Storage.loadObject(currentLevel.day);
        const nux = await nuxStorage.loadObject();
        const userSettings = await userStorage.loadObject()

        store.dispatch(loadHistory());

        store.dispatch(resetNuxState(nux));
        store.dispatch(resetUserSettings(userSettings))
        store.dispatch(loadWeb3State(web3));

        console.log("todays state is: ", s);
        // Reset state must come last
        store.dispatch(resetState(s));

        const sawTutorial = await playpass.storage.get("sawTutorial");
        if (!sawTutorial) {
          void playpass.storage.set("sawTutorial", true);
          store.dispatch(needsTutorial());
        }

        if (playpass.account.isLoggedIn()) {
          store.dispatch(login(playpass.account.getPlayerId()!));
        }

        if (timerForTomorrow) {
          clearTimeout(timerForTomorrow);
          timerForTomorrow = undefined;
        }
        console.log(currentLevel.tomorrow);
        const timeTillTomorrow = currentLevel.tomorrow - new Date().getTime();
        console.log(timeTillTomorrow);
        timerForTomorrow = setTimeout(
          () => store.dispatch(loadLevel()),
          timeTillTomorrow + 1000
        );
      },
    })
  );

  store.dispatch(
    addAppListener({
      matcher: isAnyOf(
        needsTutorial,
        resetState,
        addLetter,
        removeLetter,
        submitAnswer,
        setNuxState,
        shareLevel
      ),
      effect: (action, listenerApi) => {
        console.log("saving game state...");
        const state = listenerApi.getState().game.playerState;
        const gameState = listenerApi.getState().game.gameState;

        // Update storage
        void d.saveObject(state);

        // Log the actions
        const oldState = listenerApi.getOriginalState().game.playerState;
        const levelState = listenerApi.getState().game.levelState;

        if (action.type === setNuxState.type) {
          void nuxStorage.saveObject(listenerApi.getState().game.nuxState)
        }

        playpass.analytics.setUserProperties({
          realtimeDay: levelState.day,
          realtimeGameState: gameState,
          realtimeAnswer: levelState.a,
        });

        switch (action.type) {
          case needsTutorial.type:
            playpass.analytics.track("TutorialShow");
            break;

          case resetState.type:
            playpass.analytics.track("PuzzleStart", {
              guessedWords: state.marks.length,
              day: state.day,
            });

            if (
              GameLogic.isSolved(state) ||
              GameLogic.isLost(state, levelState)
            ) {
              playpass.analytics.track("PuzzleComplete", {
                guessedWords: state.marks.length,
                day: state.day,
              });
            }

            break;
          case addLetter.type:
            playpass.analytics.track("AddLetter", {
              currentWord: state.words[state.words.length - 1],
            });
            break;
          case removeLetter.type:
            playpass.analytics.track("RemoveLetter", {
              currentWord: state.words[state.words.length - 1],
            });
            break;
          case submitAnswer.type:
            if (oldState.marks.length !== state.marks.length) {
              playpass.analytics.track("RowComplete", {
                row: state.marks.length,
              });
            }

            if (
              GameLogic.isSolved(state) ||
              GameLogic.isLost(state, levelState)
            ) {
              playpass.analytics.track("PuzzleComplete", {
                guessedWords: state.marks.length,
                day: state.day,
              });
            }
            break;
          case shareLevel.type:
            playpass.analytics.track("ShareComplete");
            break;
        }
      },
    })
  );

  store.dispatch(
    addAppListener({
      matcher: isAnyOf(login, logout),
      effect: (action, listenerApi) => {
        const oldLoginState = listenerApi.getOriginalState().game.loginState;
        const { loginState } = listenerApi.getState().game;

        if (
          oldLoginState.isLoggedIn !== loginState.isLoggedIn ||
          oldLoginState.playerID !== loginState.playerID
        ) {
          console.log("reloading game state...");
          reload().catch(console.error);
        }
      },
    })
  );
  
  store.dispatch(
    addAppListener({
      matcher: isAnyOf(
         linkWallet.fulfilled,
         linkWallet.pending,
         linkWallet.rejected,
         claimReward.pending,
         claimReward.rejected,
         claimReward.fulfilled
      ),
      effect: (action, listenerApi) => {
        const day = listenerApi.getState().game.levelState.day
        const state = listenerApi.getState().web3

        switch (action.type) {
          case linkWallet.fulfilled.type:
            playpass.analytics.track("WalletLinked", {
              walletAddress: state.walletAddress
            })
            playpass.analytics.setUserProperties({
              realtimeWalletLinked: true,
              realtimeWalletAddress: state.walletAddress
            });
            break;
          case linkWallet.pending.type:
            playpass.analytics.track("LinkWalletPending")
            break;
          case linkWallet.rejected.type:
            playpass.analytics.track("LinkWalletError", {
              errorMessage: state.error
            })
            break
          case claimReward.pending.type:
            playpass.analytics.track("RewardPending")
            break;
          case claimReward.rejected.type:
            playpass.analytics.track("RewardClaimError", {
              errorMessage: state.error
            })
            break
          case claimReward.fulfilled.type:
            playpass.analytics.track("RewardClaimed")
            break;
        }

        // Update storage
        void web3Storage.saveObject(day, state)
      },
    }),
  )

  await reload();
};

setup().catch(console.error);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
