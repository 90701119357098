import { useAppSelector } from '../app/hooks'
import Panel from '../components/Panel'
import { GUESS_LENGTHS } from '../config/game'
import {
  GameState,
  selectGameState,
  selectLevelState,
  selectPlayerState,
} from '../features/game/gameSlice'
import { onShareClick } from './share'

const numberLanguage = {
  1: '1st',
  2: '2nd',
  3: '3rd',
  4: '4th',
  5: '5th',
  6: '6th',
  7: '7th',
  8: '8th',
  9: '9th',
}

const Distribution = ({
  state,
  numberWon,
}: {
  state: GameState['playerState']
  numberWon: number
}) => {
  const guessDistributions = state.wins.map((i) => {
    return i.reduce((p, c) => (c.win ? p + 1 : p), 0)
  })

  const [bars, text] = guessDistributions.slice(0, 9).reduce(
    (p, d, i) => {
      p[0].push(
        <div key={'bar' + i} className="barBackground">
          <div
            className="bar"
            style={{ width: Math.floor(100 * (d / numberWon)) + '%' }}
          >
            {' '}
          </div>
        </div>,
      )

      p[1].push(
        <div key={'stat' + i} className="stat">
          {d} {d === 1 ? 'win' : 'wins'} on{' '}
          {numberLanguage[(i + 1) as 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9]} guess
        </div>,
      )

      return p
    },
    [[] as Array<JSX.Element>, [] as Array<JSX.Element>],
  )

  return (
    <div className="distributions">
      <div className="bars">{bars}</div>
      <div className="text">{text}</div>
    </div>
  )
}

const StatsView = (props: { onBack: () => void }) => {
  const state = useAppSelector(selectPlayerState)
  const setup = useAppSelector(selectLevelState)
  const gameState = useAppSelector(selectGameState)

  const { wins } = state

  const [numberWon, totalGames] = wins.reduce(
    (prev, curr) => {
      const [wins, totalGames] = curr.reduce(
        (p, c) => {
          return [p[0] + (c.win ? 1 : 0), p[1] + 1]
        },
        [0, 0],
      )

      return [prev[0] + wins, prev[1] + totalGames]
    },
    [0, 0],
  )

  return (
    <div className="statsView">
      <Panel onClose={() => props.onBack()} showClose={true}>
        <h3>Statistics</h3>
        <div className="metrics">
          <div className="wins">
            <div>Games Won</div>
            <div>{`${numberWon} / ${totalGames}`}</div>
          </div>
          <div className="streak">
            <div>Max Streak</div>
            <div>{state.maxStreak}</div>
          </div>

          <div className="winPerc">
            <div>Win %</div>
            <div>
              {totalGames !== 0
                ? Math.floor((numberWon / totalGames) * 100)
                : 0}
            </div>
          </div>
        </div>
        <h2>Guess Distribution</h2>
        <Distribution numberWon={numberWon} state={state} />
      </Panel>
      <button
        id="shareBtn"
        onClick={() => onShareClick(state, setup, gameState)}
      >
        🗨️ Share
      </button>
    </div>
  )
  return <div />
}
export default StatsView
