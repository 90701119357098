import React, { useEffect, useState } from 'react'
import './App.css'
import './skin/skin.css'
import './boilerplate/boilerplate.css'
import SettingsView from './views/SettingsView'
import HelpView from './views/HelpView'
import StatsView from './views/StatsView'
import GameView from './views/GameView'
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  Navigate,
} from 'react-router-dom'
import { useAppDispatch, useAppSelector } from './app/hooks'
import {
  sawTutorial,
  selectGameState,
  selectLevelState,
  selectNeedsTutorial,
} from './features/game/gameSlice'
import Title from './skin/Title'
import classNames from 'classnames'
import * as playpass from 'playpass'
import WalletView from './views/WalletView'
import { HELP_PATH, SETTINGS_PATH, STATS_PATH, WALLET_PATH } from './Paths'

function App() {
  const dispatch = useAppDispatch()
  const needsTutorial = useAppSelector(selectNeedsTutorial)
  const gameState = useAppSelector(selectGameState)

  const navigate = useNavigate()
  const location = useLocation()
  const pathname = location.pathname

  const toggleScreen = (screen: string) => {
    if (pathname === screen) {
      playpass.analytics.track('PageHide', { page: screen })
      playpass.analytics.track('PageShow', { page: '/' })
      navigate('/')
    } else {
      if (screen.startsWith(HELP_PATH)) {
        playpass.analytics.track('TutorialShow')
      }
      playpass.analytics.track('PageHide', { page: pathname })
      playpass.analytics.track('PageShow', { page: screen })
      navigate(screen)
    }
  }

  if (gameState === 'LOADING') {
    return <div />
  }

  if (needsTutorial && location.pathname !== HELP_PATH) {
    return <Navigate replace to={HELP_PATH} />
  }

  return (
    <div className="App">
      <header>
        <nav>
          <div
            id="helpBtn"
            className={classNames({
              active: pathname.startsWith(HELP_PATH),
              button: true,
            })}
            onClick={() => toggleScreen(HELP_PATH)}
          ></div>
          <h1 onClick={() => navigate('/')}>
            <Title />
          </h1>
          <div
            id="statsBtn"
            className={classNames({
              active: pathname.startsWith(STATS_PATH),
              button: true,
            })}
            onClick={() => toggleScreen(STATS_PATH)}
          ></div>
          <div
            id="walletBtn"
            className={classNames({
              active: pathname.startsWith(WALLET_PATH),
              button: true,
            })}
            onClick={() => toggleScreen(WALLET_PATH)}
          ></div>
          <div
            id="settingsBtn"
            className={classNames({
              active: pathname.startsWith(SETTINGS_PATH),
              button: true,
            })}
            onClick={() => toggleScreen(SETTINGS_PATH)}
          ></div>
        </nav>
      </header>
      <div className="mainArea">
        <Routes>
          <Route
            path={HELP_PATH}
            element={
              <HelpView
                onBack={() => {
                  dispatch(sawTutorial())
                  playpass.analytics.track('TutorialHide')
                  toggleScreen(HELP_PATH)
                }}
              />
            }
          />
          <Route
            path={STATS_PATH}
            element={<StatsView onBack={() => toggleScreen(STATS_PATH)} />}
          />
          <Route
            path={WALLET_PATH + '/*'}
            element={<WalletView onBack={() => toggleScreen(WALLET_PATH)} />}
          />
          <Route
            path={SETTINGS_PATH}
            element={
              <SettingsView onBack={() => toggleScreen(SETTINGS_PATH)} />
            }
          />
          <Route path="/*" element={<Navigate to="/" />} />
          <Route path="/" element={<GameView />} />
        </Routes>
      </div>
    </div>
  )
}

export default App
