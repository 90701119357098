import { useEffect, useRef } from 'react'
import SizeAwareDiv from './SizeAwareDiv'

import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/all'
gsap.registerPlugin(ScrollToPlugin)

const Panel = (props: {
  children: JSX.Element | JSX.Element[]
  showClose?: boolean
  onClose?: () => void
  scrollable?: boolean
  scrollableMaxHeight?: string
  scrollToBottom?: boolean
}) => {
  const divRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (props.scrollToBottom && divRef.current !== null) {
      gsap.to(divRef.current, {
        duration: 1,
        scrollTo: divRef.current.scrollHeight,
      })
    }
  }, [])

  return (
    <SizeAwareDiv
      className="panel"
      render={(w, h) => {
        return (
          <div>
            {props.showClose && (
              <div
                className="x"
                onClick={() => props.onClose && props.onClose()}
              ></div>
            )}
            {props.scrollable && props.scrollableMaxHeight ? (
              <div
                ref={divRef}
                className="content scrollable"
                style={{ maxHeight: props.scrollableMaxHeight }}
              >
                {props.children}
              </div>
            ) : (
              <div className="content">{props.children}</div>
            )}
          </div>
        )
      }}
    ></SizeAwareDiv>
  )
}
export default Panel
