import * as playpass from "playpass";
import { Web3State } from "../features/web3/web3slice";

const TAG = 'web3state';
type StoredWeb3State = Pick<Web3State, 'walletAddress' | 'claimed' | 'claimedTokenUrl'>

export class Web3Storage {
  constructor() {
  }

  /** Loads an web3 data from storage */
  async loadObject(day: number): Promise<StoredWeb3State> {
    try {
      const state = (await playpass.storage.get(TAG)) as StoredWeb3State & {day: number};
      if (state) {
        if(state.day !== day) {
          return {
            walletAddress: state.walletAddress,
            claimed: false
          };
        }
        return state;
      }

      return { claimed: false }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  /** Saves an object to storage, which will expire the next day. */
  async saveObject(day: number, state: StoredWeb3State) {
    await playpass.storage.set(TAG, {...state, day});
  }
}
