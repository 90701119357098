import * as playpass from "playpass";
import { GameState } from "../features/game/gameSlice";

type NuxState = GameState['nuxState'];
const TAG = 'nux1';

export class NuxStorage {
  constructor() {
  }

  /** Loads an object from storage, returning null if there was no object previously saved today. */
  async loadObject(): Promise<NuxState> {
    try {
      const state = (await playpass.storage.get(TAG)) as NuxState;
      if (state) {
        return state;
      }

      return {
        walletNuxSeen: false,
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  /** Saves an object to storage, which will expire the next day. */
  async saveObject(state: NuxState) {
    await playpass.storage.set(TAG, state);
  }
}
