import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getWeb3Wallet } from "../../api/web3";
import * as printerApi from "../../api/printer";
import { RootState } from "../../app/store";

export const linkWallet = createAsyncThunk(
  "web3/linkWallet",
  async () => {
    return getWeb3Wallet();
  }
);

export const claimReward = createAsyncThunk(
  "web3/claimReward",
  async (
    meta: { day: number; attempts: number, marks: string[] },
    { getState }
  ) => {
    const { web3 } = getState() as { web3: Web3State };
    const { uri } = await printerApi.claimReward({
      challengeMeta: {
        attempts: meta.attempts,
        marks: meta.marks,
      },
      day: meta.day,
      walletAddress: web3.walletAddress!,
    });
    return uri;
  }
);

export type Web3State = {
  walletAddress?: string;
  claimingInProgress: boolean;
  claimed: boolean;
  error?: string;
  claimedTokenUrl?: string;
};

const initialState: Web3State = {
  claimingInProgress: false,
  walletAddress: undefined,
  error: undefined,
  claimed: false,
}

export const web3Slice = createSlice({
  name: 'web3',
  initialState,
  reducers: {
    loadWeb3State: (state, action) => {
      state.walletAddress = action.payload.walletAddress;
      state.claimed = action.payload.claimed;
      state.claimedTokenUrl = action.payload.claimedTokenUrl;
    },
  },
  extraReducers: (builder) =>{
    // LINK WALLET
     builder
      .addCase(linkWallet.fulfilled, (state, action) => {
        state.walletAddress = action.payload;
      })
      .addCase(linkWallet.rejected, (state, action) => {
        state.error = action?.error?.message as string;
      })
    // CLAIM REWARD
      .addCase(claimReward.pending, (state, _) => {
        state.claimingInProgress = true;
      })
      .addCase(claimReward.rejected, (state, action: any) => {
        console.log(action);
        state.claimingInProgress = false;
        if (action?.error?.message === "Token already claimed") {
          state.claimed = true;
        }
        state.error = action?.error?.message as string;
      })
      .addCase(claimReward.fulfilled, (state, action) => {
        state.claimed = true;
        state.claimingInProgress = false;
        state.claimedTokenUrl = action.payload;
      });
  }
});

export const { loadWeb3State } = web3Slice.actions;

export const selectWeb3State = (state: RootState) => state.web3;
export const selectIsWalletConnected = (state: RootState) => !!state.web3?.walletAddress;

export default web3Slice.reducer
