import { configureStore, ThunkAction, Action, createListenerMiddleware } from '@reduxjs/toolkit';
import gameReducer from '../features/game/gameSlice';
import web3Reducer from '../features/web3/web3slice';
import historyReducer from '../features/history/historySlice';

export const listenerMiddleware = createListenerMiddleware()
export const store = configureStore({
  reducer: {
    history: historyReducer,
    game: gameReducer,
    web3: web3Reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(listenerMiddleware.middleware)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
