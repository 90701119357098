import { ExampleGrid } from '../components/Grid'
import Panel from '../components/Panel'
import SizeAwareDiv from '../components/SizeAwareDiv'

const HelpViewContent = (props: {
  onBack: () => void
  width: number
  height: number
}) => {
  return (
    <div className="screen" id="helpScreen">
      <Panel showClose={true} onClose={props.onBack}>
        <p>
          3dle is the wordle game with a web3 !vibe. Everyday, guess a new web3
          related word. After every guess, you'll see how close your chosen word
          is to the real answer.
        </p>
        <h4>
          Solve a new puzzle everyday to win your free Proof of Play (POP) NFT.
          Collect POP tokens to unlock features, game modes and other rewards.
        </h4>
        <ExampleGrid
          marks="bnbnn"
          word="GREAT"
          width={props.width}
          height={70}
        />

        <p>
          <b>
            <span className="green">Green</span>
          </b>{' '}
          indicates you guessed a correct letter, in the correct position.
        </p>

        <ExampleGrid
          marks="cnncn"
          word="UNDER"
          width={props.width}
          height={70}
        />

        <p>
          <b>
            <span className="yellow">Yellow</span>
          </b>{' '}
          indicates you guessed a correct letter, but in the wrong position.
        </p>
        <br></br>
        <p className="legal">
        By playing you agree to our{' '}
        <a href="https://www.play.co/tos" target="_blank">
          Terms of Service
        </a>{' '}
        and{' '}
        <a href="https://www.play.co/privacy" target="_blank">
          Privacy Policy
        </a>
        .
      </p>
      </Panel>
      <button id="helpBackBtn" onClick={props.onBack}>
        Play
      </button>
    </div>
  )
}

const HelpView = (props: { onBack: () => void }) => {
  return (
    <SizeAwareDiv
      render={(width, height) => (
        <HelpViewContent width={width} height={height} onBack={props.onBack} />
      )}
    />
  )
}

export default HelpView
