import { State } from '../storage/daily'
import Keyboard from '../components/Keyboard'
import Grid, { ResizableGrid } from '../components/Grid'
import GameLogic from '../GameLogic'
import { useAppDispatch, useAppSelector, usePrevious } from '../app/hooks'
import {
  addLetter,
  GameState,
  removeLetter,
  selectGameState,
  selectLevelState,
  selectMessage,
  selectPlayerState,
  selectUserSettings,
  setMessage,
  submitAnswer,
  shareLevel,
} from '../features/game/gameSlice'
import * as playpass from 'playpass'
import SizeAwareDiv from '../components/SizeAwareDiv'
import { useEffect, useState } from 'react'
import Banner from '../components/Banner'
import CountdownTimer from '../components/CountdownTimer'
import { onShareClick } from './share'
import gsap from 'gsap'
import ClaimFlow from './../Web3Claim'

function onClaimNFT() {
  alert('Coming soon!')
}

const PlayView = (props: {
  setup: GameState['levelState']
  state: State
  message: GameState['message']
  gameState: GameState['gameState']
  onKey: (e: string) => void
}) => {
  const userSettings = useAppSelector(selectUserSettings)
  const { state, setup, gameState } = props
  const dispatch = useAppDispatch()
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(
    gameState === 'PENDING',
  )

  const prevGameState = usePrevious(gameState)
  const gameJustFinished =
    (prevGameState &&
      prevGameState === 'PENDING' &&
      (gameState === 'WIN' || gameState === 'LOSS')) ||
    false

  useEffect(() => {
    // animate keyboard and results screen

    if (gameJustFinished) {
      const animTime = 1.0
      const delayTime = 1.0
      gsap.to('.keyboardContainer', {
        x: '-100vw',
        duration: animTime,
        delay: delayTime,
      })
      gsap.to('.resultsContainer', {
        x: '0vw',
        duration: animTime,
        delay: delayTime,
      })
    }
  }, [gameJustFinished])

  return (
    <div className="gameScreen" id="playingScreen">
      {showWelcomeMessage ? (
        <Banner
          text={"Guess today's word"}
          type={'info'}
          onInfoDismissed={() => {
            setShowWelcomeMessage(false)
          }}
        />
      ) : (
        <Banner
          text={props.message.text}
          type={props.message.type}
          onInfoDismissed={() => {
            dispatch(setMessage({ text: '', type: 'none' }))
          }}
        />
      )}
      <SizeAwareDiv
        className="primary"
        render={(width, height) => (
          <ResizableGrid
            width={width}
            height={height}
            state={state}
            length={setup.a.length}
            guesses={setup.guesses}
          />
        )}
      />
      {userSettings.showHints && setup.hint && <HintPanel hint={setup.hint!} />}
      <div className="bottom">
        <div
          className="keyboardContainer"
          style={{
            transform:
              gameJustFinished || (gameState !== 'WIN' && gameState !== 'LOSS')
                ? undefined
                : 'translateX(100vw)',
          }}
        >
          <Keyboard state={state} onKey={(e) => props.onKey(e)} />
        </div>
        <div
          className="resultsContainer"
          style={{
            transform:
              !gameJustFinished && (gameState === 'WIN' || gameState === 'LOSS')
                ? undefined
                : 'translateX(100vw)',
          }}
        >
          <b>Share to Collect a Proof of Play NFT</b>
          <ResultsView2 state={state} setup={setup} status={gameState} />
        </div>
      </div>
    </div>
  )
}

const ResultsView2 = (props: {
  state: State
  setup: GameState['levelState']
  status: 'WIN' | 'LOSS' | 'LOADING' | 'PENDING'
}) => {
  const dispatch = useAppDispatch()
  return (
    <div className="resultContent">
      <div className="primary">
        <div className="actions">
          <button
            id="shareBtn"
            onClick={() => {
              onShareClick(
                props.state,
                props.setup,
                props.status
              ).then((result) => {
                dispatch(shareLevel())
              })
            }}
          >
            {props.state.didShare ? '🗨️ Share' : '🗨️ Share To Claim NFT'}
          </button>
          {props.state.didShare && (
            <ClaimFlow day={props.state.day} attempts={props.state.marks.length} marks={props.state.marks}/>
          )}
        </div>
        <CountdownTimer tomorrow={props.setup.tomorrow}/>
      </div>
    </div>
  )
}

const HintPanel = (props: { hint: string }) => {
  return <p className="hint">Hint: {props.hint}</p>
}

const GameView = () => {
  const playerState = useAppSelector(selectPlayerState)
  const levelState = useAppSelector(selectLevelState)
  const gameState = useAppSelector(selectGameState)
  const messageState = useAppSelector(selectMessage)
  const dispatch = useAppDispatch()

  const handleKeyEvent = (key: string) => {
    if (
      GameLogic.isSolved(playerState) ||
      playerState.marks.length === levelState.guesses
    ) {
      return
    }

    if (key == 'Enter') {
      dispatch(submitAnswer())
    } else if (key == 'Delete') {
      dispatch(removeLetter())
    } else {
      dispatch(addLetter(key))
    }
  }

  if (gameState === 'LOADING') {
    debugger
    return <div>Loading...</div>
  }

  return (
    <PlayView
      onKey={(e) => handleKeyEvent(e)}
      gameState={gameState}
      state={playerState}
      setup={levelState}
      message={messageState}
    />
  )
}
export default GameView
