import * as playpass from 'playpass'
import { GameState } from '../features/game/gameSlice'

const MILLISECONDS_IN_DAY = 1000 * 60 * 60 * 24


type WinRecord = { day: number; guesses: number; win: boolean }
type Wins = WinRecord[][]

function newWins(): Wins {
  return [[], [], [], [], [], [], [], [], [], []]
}

export type State = {
  words: string[]
  marks: string[]
  currentStreak: number
  maxStreak: number
  wins: Wins
  day: number
  didShare: boolean
}

export class Daily {
  public day: number = -1;

  constructor() {
  }

  resetDay(day: number) {
    this.day = day
  }

  /** Loads an object from storage, returning null if there was no object previously saved today. */
  async loadObject(): Promise<GameState['playerState']> {
    try {
      const state = (await playpass.storage.get('daily_v1')) as State
      const newState = {
        words: [''],
        marks: [],
        currentStreak: 0,
        maxStreak: 0,
        wins: newWins(), // wins count for each successful attempt
        day: 0,
        didShare: false,
      } as GameState['playerState']
      if (!state) {
        return {
          ...newState,
          day: this.day,
        }
      } else if (state.day !== this.day) {
        return {
          ...newState,
          ...state,
          words: [''],
          marks: [],
          day: this.day,
          didShare: false,
        }
      } else {
        return {
          ...newState,
          ...state,
        }
      }
    } catch (e) {
      console.error(e)
      throw e
    }
  }

  /** Saves an object to storage, which will expire the next day. */
  async saveObject(state: State) {
    await playpass.storage.set('daily_v1', state)
  }

  processWins(state: State) {
    const { wins } = state

    const emojiBadge = []
  }
}
