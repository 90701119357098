import * as playpass from 'playpass'
import { GameState } from '../features/game/gameSlice'

type UserSettingsState = GameState['userSettings']
const TAG = 'userSettings1'

export class UserSettingsStorage {
  constructor() {}

  /** Loads an object from storage, returning null if there was no object previously saved today. */
  async loadObject(): Promise<UserSettingsState> {
    try {
      const state = (await playpass.storage.get(TAG)) as UserSettingsState
      if (state) {
        return state
      }

      return { showHints: true } as UserSettingsState
    } catch (e) {
      console.error(e)
      throw e
    }
  }

  /** Saves an object to storage, which will expire the next day. */
  async saveObject(state: UserSettingsState) {
    await playpass.storage.set(TAG, state)
  }
}
