import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import Panel from '../components/Panel'
import { GUESS_LENGTHS } from '../config/game'
import {
  GameState,
  selectGameState,
  selectLevelState,
  selectNuxState,
  selectPlayerState,
  setNuxState,
} from '../features/game/gameSlice'
import { HistorySlice, loadHistory, selectHistory } from '../features/history/historySlice'
import {
  linkWallet,
  selectIsWalletConnected,
  selectWeb3State,
  Web3State,
} from '../features/web3/web3slice'
import ClaimFlow from '../Web3Claim'
import { WALLET_HELP_PATH, WALLET_PATH } from '../Paths'
import './WalletView.css'

function toSortedArray<T>(
  map:
    | {
        [key: number]: T
      }
    | Record<number, T>,
): Array<T & { index: number }> {
  const res: Array<T & { index: number }> = []

  for (const item in map) {
    const i = map[item] as T
    res.push({ index: (item as any) as number, ...i })
  }

  return res.sort((a, b) => a.index - b.index)
}


const PuzzleView = (props: {
  puzzle: HistorySlice['history'][1]
  onTap: () => void
  isToday: boolean
  isSelected: boolean
}) => {
  const classes = classNames({
    today: props.isToday,
    puzzle: true,
    empty: !props.puzzle.tokenId,
    selected: props.isSelected,
  })
  return (
    <div className={classes} onClick={props.onTap}>
      <PuzzleImage puzzle={props.puzzle}/>
      <p>{props.puzzle.day}</p>
    </div>
  )
}

const PuzzleList = (props: {
  levelHistory: HistorySlice['history'],
  levelState: GameState['levelState']
  playerState: GameState['playerState']
  onTap: (key: number) => void
  selectedPuzzle?: number
}) => {
  return (
    <div className="puzzleList">
      {Object.entries(props.levelHistory).map(([day, level]) => {
        const dayNum = parseInt(day, 10);
        const puzzle = {...level}
        if(puzzle.day === props.playerState.day){
          puzzle.marks = props.playerState.marks
        }
        return (
          <PuzzleView
            key={'puzz' + day}
            puzzle={puzzle}
            isToday={dayNum == props.levelState.day}
            isSelected={dayNum == props.selectedPuzzle}
            onTap={() => {
              props.onTap(dayNum)
            }}
          />
        )
      })}
    </div>
  )
}

const PuzzleImage = (props: {
  puzzle: HistorySlice['history'][1]
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const WIDTH = 40
  const HEIGHT = 40

  const B_COLOR = '#35EDAB'
  const C_COLOR = '#FEC047'
  const N_COLOR = '#94969A'
  const DEFAULT_COLOR = '#020614'

  const COLOR_MAP = {
    b: B_COLOR,
    c: C_COLOR,
    n: N_COLOR,
  }

  useEffect(() => {
    if (!canvasRef.current) {
      return
    }

    const width = props.puzzle.word.length
    const height = GUESS_LENGTHS[width as 3 | 4 | 5 | 6 | 7]

    const wWidth = WIDTH - (width - 1)
    const wHeight = HEIGHT - (height - 1)

    const size = Math.floor(height > width ? wHeight / height : wWidth / width)
    canvasRef.current.width = size * width + (width - 1) + 2
    canvasRef.current.height = size * height + (height - 1) + 2

    const offsetX = 1 // Math.floor((WIDTH - (size * width + (width - 1))) / 2.0)
    const offsetY = 1 // Math.floor((HEIGHT - (size * height + (height - 1))) / 2.0)

    const context = canvasRef.current!.getContext('2d')!
    context.clearRect(0, 0, WIDTH, HEIGHT)
    context.fillStyle = '#FFFFFFAA'
    context.fillRect(0, 0, WIDTH, HEIGHT)

    const marks = props.puzzle.marks

    for (var y = 0; y < height; y++) {
      for (var x = 0; x < width; x++) {
        if (marks && marks[y]) {
          context.fillStyle =
            COLOR_MAP[marks[y][x] as 'b' | 'c' | 'n'] || DEFAULT_COLOR
        } else {
          context.fillStyle = DEFAULT_COLOR
        }

        context.fillRect(
          offsetX + x * (size + 1),
          offsetY + y * (size + 1),
          size,
          size,
        )
      }
    }
  }, [])

  if (props.puzzle.imagePNGImageDataURL) {
    return <img src={props.puzzle.imagePNGImageDataURL} />
  }

  return <canvas width={40} height={40} ref={canvasRef} />
}

const Nux = (props: { onHide: () => void }) => {
  useEffect(() => {}, [])

  return (
    <div>
      <h2>Proof of Play NFT collection</h2>
      <p>
        Show off your web3 worldliness by earning our free <b>Proof of Play (POP)</b> NFTs! 
        For each daily puzzle you solve, you can claim a completely free POP token on that 
        day and specific to that day’s puzzle!
      </p>
      <p>
        POP tokens prove the puzzle was solved and for hodlers will unlock special game modes and features in the future! 
        If you miss out on playing or claiming, no worries! You can buy an existing POP token on our marketplace.
      </p>
      <button onClick={props.onHide}>View My Collection</button>
    </div>
  )
}

const WalletActionButton = (props: {
  day: number
  selectedLevel: HistorySlice['history'][1]
  levelState: GameState['levelState']
  web3State: Web3State
  playerState: GameState['playerState']
  gameState: GameState['gameState']
  onBack: () => void
}) => {
  const { day, selectedLevel, playerState, gameState, web3State } = props

  const PrimaryButton = () => {
    let onClick = () => {}
    let text = ''
    if (props.gameState != 'WIN' && day == selectedLevel.day && !selectedLevel.tokenId) {
      // Haven't completed today's puzzle
      text = "Play Today's Puzzle"
      onClick = props.onBack
    } else if (props.gameState === 'WIN' && day == selectedLevel.day && !selectedLevel.tokenId && !web3State.claimed) {
      return <ClaimFlow day={selectedLevel.day} attempts={playerState.marks.length} marks={playerState.marks}/>
    } else if (selectedLevel.nftURL) {
      text = 'View'
      onClick = () => {
        window.open(selectedLevel!.nftURL, '_blank')
      }
    } else if (playerState.day == day && web3State.claimed) {
      text = 'View'
      onClick = () => {
        window.open(web3State.claimedTokenUrl, '_blank')
      }
    } else {
      text = 'Purchase'
      onClick = () => {
        // TODO: filtered store link
        window.open(process.env.REACT_APP_IMX_MARKET_URI, '_blank')
      }
    }
    return <button onClick={onClick}>{text}</button>
  }

  return (
    <div className="walletButtons">
      <PrimaryButton />
    </div>
  )
}

const WalletView = (props: { onBack: () => void }) => {
  const history = useAppSelector(selectHistory)
  const levelState = useAppSelector(selectLevelState)
  const gameState = useAppSelector(selectGameState)
  const playerState = useAppSelector(selectPlayerState)
  const nuxState = useAppSelector(selectNuxState).walletNuxSeen
  const isWalletConnected = useAppSelector(selectIsWalletConnected)
  const web3State = useAppSelector(selectWeb3State)
  const dispatch = useAppDispatch()

  const [level, setLevel] = useState(levelState.day)
  const navigate = useNavigate()
  const { pathname } = useLocation()

  // load history if the wallet is connected
  useEffect(() => {
    if (isWalletConnected) {
      dispatch(loadHistory())
    }
  }, [isWalletConnected])


  return (
    <div className="walletView">
      <Panel
        onClose={props.onBack}
        showClose={true}
        scrollable={true}
        scrollableMaxHeight={'58vh'}
        scrollToBottom={true}
      >
        <Routes>
          <Route
            path={'help'}
            element={
              <Nux
                onHide={() => {
                  dispatch(setNuxState({ key: 'walletNuxSeen', value: true }))
                  navigate(WALLET_PATH)
                }}
              />
            }
          />
          <Route
            path={'*'}
            element={
              !nuxState ? (
                <Navigate replace to={WALLET_HELP_PATH} />
              ) : (
                <div>
                  <h2 className="walletTitle">Proof of Play Collection</h2>
                  {history.loading && <div>Loading...</div>}
                  {!history.loading &&
                    <>{isWalletConnected ? (
                      <PuzzleList
                        levelHistory={history.history}
                        playerState={playerState}
                        levelState={levelState}
                        selectedPuzzle={level}
                        onTap={(selectedLevel) => {
                        setLevel(selectedLevel)
                        }}/>
                    ) : <button onClick={() => dispatch(linkWallet())}>Link wallet</button>
                    }</>
                  }
                </div>
              )
            }
          />
        </Routes>
      </Panel>
      {isWalletConnected && !history.loading && !pathname.startsWith(WALLET_HELP_PATH) && level && history.history[level] && (
        <WalletActionButton
          onBack={props.onBack}
          selectedLevel={history.history[level]}
          web3State={web3State}
          gameState={gameState}
          levelState={levelState}
          playerState={playerState}
          day={levelState.day}
        />
      )}
    </div>
  )
}

export default WalletView
