import* as printerApi from './../../api/printer'
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from '../../app/store';

export type GameHistoryValue = {
  word: string;
  day: number,
  maxGuesses: number,
  imagePNGImageDataURL?: string,
  marks?: string[],
  tokenId?: string,
  nftURL?: string,
}

export type HistorySlice = {
  loading: boolean,
  history: Record<number, GameHistoryValue> 
};

const initialState: HistorySlice = {
  history: {},
  loading: false,
};

export const loadHistory = createAsyncThunk(
  "web3/loadHistory",
  async (_, { getState, rejectWithValue }) => {
      const walletAddress = (getState() as RootState).web3.walletAddress;
      if(!walletAddress) return rejectWithValue('No wallet configured');
      const { history } = await printerApi.getHistory(walletAddress!);
      return history;
    }
)

const historySlice = createSlice({
  name: 'history',
  reducers: {
  },
  initialState,
  extraReducers: builder => {
    builder.addCase(loadHistory.fulfilled, (state, action) => {
      state.history = action.payload.reduce((acc, level) => {
        acc[level.day] = {
          word: level.word,
          day: level.day,
          marks: level.marks,
          tokenId: level.tokenId,
          nftURL: level.nftURL,

          // TODO
          maxGuesses: 5, 
          imagePNGImageDataURL: ''
        };
        return acc;
      }, {} as Record<number, GameHistoryValue>);
      state.loading = false;
    }).addCase(loadHistory.pending, (state) => {
      state.loading = true;
    }).addCase(loadHistory.rejected, (state) => {
      state.loading = false;
    });
  }
})

export const selectHistory = (state: RootState) => state.history

export default historySlice.reducer;
