import { useEffect, useState } from 'react'
import {
  getHoursUntil,
  getMinutesUntil,
  getSecondsUntil,
} from '../boilerplate/timer'

const CountdownTimer = (props: {tomorrow: number}) => {
  const [h, setH] = useState(getHoursUntil(props.tomorrow))
  const [m, setM] = useState(getMinutesUntil(props.tomorrow))
  const [s, setS] = useState(getSecondsUntil(props.tomorrow))

  useEffect(() => {
    let c = true
    const updateTime = () => {
      if (!c) {
        return
      }
      const next = props.tomorrow
      const hr = getHoursUntil(next)
      const mi = getMinutesUntil(next)
      const se = getSecondsUntil(next)

      setH('' + hr)
      setM(mi.toString().padStart(2, '0'))
      setS(se.toString().padStart(2, '0'))
    }

    setInterval(updateTime, 1000)
  }, [])

  return (
    <p>
      <span id="timeLeft">
        {h}h {m}m {s}s
      </span>{' '}
      until next question.
    </p>
  )
}

export default CountdownTimer
