import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as printerApi from "./../../api/printer";
import GameLogic from "../../GameLogic";
import { GUESS_LENGTHS } from "./../../config/game";
import { getWeb3Wallet } from "../../api/web3";
import { Root } from "react-dom/client";
import { RootState, AppDispatch } from "../../app/store";
import { isValidWord } from "../../boilerplate/dictionary";

type WinRecord = { day: number; guesses: number; win: boolean };
type Wins = WinRecord[][];

function newWins(): Wins {
  return [[], [], [], [], [], [], [], [], [], []];
}

export type GameHistoryValue = {
  day: number;
  marks: string[];
  maxGuesses: number;
  imagePNGImageDataURL?: string;
  nftURL?: string;
};

export type NuxState = {
  walletNuxSeen: boolean;
};

export type GameState = {
  playerState: {
    words: string[];
    marks: string[];
    currentStreak: number;
    maxStreak: number;
    wins: Wins;
    day: number;
    didShare: boolean;
  };
  levelState: {
    a: string;
    guesses: number;
    day: number;
    hint?: string;
    tomorrow: number;
  };
  needsTutorial: boolean;
  gameState: "WIN" | "LOSS" | "PENDING" | "LOADING";
  message: {
    type: "info" | "reveal" | "none";
    text: string;
  };
  userSettings: {
    showHints: boolean;
  };
  loginState: {
    isLoggedIn: boolean;
    playerID?: string;
  };
  nuxState: NuxState;
};

const initialState: GameState = {
  nuxState: {
    walletNuxSeen: false,
  },
  playerState: {
    words: [""],
    marks: [],
    currentStreak: 0,
    maxStreak: 0,
    wins: newWins(), // wins count for each successful attempt
    day: -1,
    didShare: false,
  },
  message: {
    type: "none",
    text: "",
  },
  levelState: {
    day: -1,
    a: "",
    guesses: 0,
    tomorrow: 0,
  },
  userSettings: {
    showHints: true,
  },
  needsTutorial: false,
  gameState: "LOADING",
  loginState: {
    isLoggedIn: false,
    playerID: undefined,
  },
};

function getMessageForWin(guesses: number) {
  switch (guesses) {
    case 1:
      return "Omniscient";
    case 2:
      return "Majestic";
    case 3:
      return "Sublime";
    case 4:
      return "Marvelous";
    case 5:
      return "Cool";
    default:
      return "Close";
  }
}

function clearMessage(state: GameState) {
  state.message.text = "";
  state.message.type = "none";
}

export const loadLevel = createAsyncThunk<any>(
  "game/loadLevel",
  async (_, thunkApi) => {
    const level = printerApi.getLevel();
    return level;
  }
);

export const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers: {
    login: (state, action: PayloadAction<string>) => {
      state.loginState.isLoggedIn = true;
      state.loginState.playerID = action.payload;
    },
    logout: (state) => {
      state.loginState.isLoggedIn = true;
      state.loginState.playerID = undefined;
    },
    setMessage: (state, action: PayloadAction<GameState["message"]>) => {
      state.message = action.payload;
    },
    resetNuxState: (state, action: PayloadAction<GameState["nuxState"]>) => {
      state.nuxState = action.payload;
    },
    resetUserSettings: (
      state,
      action: PayloadAction<GameState["userSettings"]>
    ) => {
      state.userSettings = action.payload;
    },
    setNuxState: (
      state,
      action: PayloadAction<{
        key: keyof GameState["nuxState"];
        value: boolean;
      }>
    ) => {
      state.nuxState[action.payload.key] = action.payload.value;
      console.log(state);
    },
    resetState: (state, action: PayloadAction<GameState["playerState"]>) => {
      if (state.levelState.guesses === 0) {
        throw new Error("Level state needs to be reset first");
      }
      state.playerState.words = action.payload.words;
      state.playerState.day = action.payload.day;
      state.playerState.marks = action.payload.marks;
      state.playerState.wins = action.payload.wins;
      state.playerState.currentStreak = action.payload.currentStreak;
      state.playerState.maxStreak = action.payload.maxStreak;
      state.playerState.didShare = action.payload.didShare;

      if (GameLogic.isSolved(state.playerState)) {
        state.gameState = "WIN";

        state.message.type = "reveal";
        state.message.text = getMessageForWin(state.playerState.marks.length);
      } else if (GameLogic.isLost(state.playerState, state.levelState)) {
        state.gameState = "LOSS";

        state.message.type = "reveal";
        state.message.text = state.levelState.a;
      } else {
        state.gameState = "PENDING";
        clearMessage(state);
      }
    },

    submitAnswer: (state) => {
      const word = state.playerState.words[state.playerState.words.length - 1];
      if (word.length < state.levelState.a.length) {
        state.message.type = "info";
        state.message.text = "Not enough letters";
        return;
      }

      if (isValidWord(word)) {
        const result = GameLogic.getMarks(word, state.levelState.a);
        console.log(result);
        state.playerState.marks.push(result);

        const isWin = GameLogic.isSolved(state.playerState);
        const isLoss = GameLogic.isLost(state.playerState, state.levelState);

        if (isWin) {
          state.gameState = "WIN";

          state.playerState.wins[state.playerState.marks.length - 1].push({
            win: true,
            day: state.playerState.day,
            guesses: state.playerState.marks.length,
          });
          state.playerState.currentStreak++;
          state.playerState.maxStreak = Math.max(
            state.playerState.currentStreak,
            state.playerState.maxStreak
          );

          state.message.type = "reveal";
          state.message.text = getMessageForWin(state.playerState.marks.length);
        } else if (isLoss) {
          state.gameState = "LOSS";

          state.playerState.wins[state.playerState.marks.length - 1].push({
            win: false,
            day: state.playerState.day,
            guesses: state.playerState.marks.length,
          });
          state.playerState.currentStreak = 0;

          state.message.type = "reveal";
          state.message.text = state.levelState.a;
        } else {
          if (state.playerState.words.length < state.levelState.guesses) {
            state.playerState.words.push("");
          }

          state.gameState = "PENDING";
        }

        // Record history
        if (isWin || isLoss) {
          const day = state.levelState.day;
          // TODO: update history?
        }
      } else {
        state.message.type = "info";
        state.message.text = "Not a valid word.";
      }
    },

    addLetter: (state, action: PayloadAction<string>) => {
      if (action.payload.length !== 1) {
        return;
      }

      const word = state.playerState.words[state.playerState.words.length - 1];
      if (word.length + 1 > state.levelState.a.length) {
        return;
      }
      state.playerState.words[state.playerState.words.length - 1] =
        word + action.payload;

      clearMessage(state);
    },

    removeLetter: (state) => {
      const word = state.playerState.words[state.playerState.words.length - 1];

      if (word.length) {
        state.playerState.words[state.playerState.words.length - 1] =
          word.substring(0, word.length - 1);
      }

      clearMessage(state);
    },

    needsTutorial: (state) => {
      state.needsTutorial = true;
    },

    sawTutorial: (state) => {
      state.needsTutorial = false;
    },

    shareLevel: (state) => {
      state.playerState.didShare = true;
    },

    toggleHints: (state, action: PayloadAction<boolean>) => {
      state.userSettings.showHints = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder.addCase(loadLevel.fulfilled, (state, action) => {
      state.levelState.day = action.payload.day;
      state.levelState.a = action.payload.a;
      state.levelState.guesses =
        GUESS_LENGTHS[action.payload.a.length as 3 | 4 | 5 | 6 | 7];
      state.levelState.tomorrow = action.payload.tomorrow;
    });
  },
});

export const selectPlayerState = (state: RootState) => state.game.playerState;
export const selectLevelState = (state: RootState) => state.game.levelState;
export const selectNeedsTutorial = (state: RootState) =>
  state.game.needsTutorial;
export const selectGameState = (state: RootState) => state.game.gameState;
export const selectMessage = (state: RootState) => state.game.message;
export const selectLoginState = (state: RootState) => state.game.loginState;
export const selectNuxState = (state: RootState) => state.game.nuxState;
export const selectUserSettings = (state: RootState) => state.game.userSettings;

export const {
  setNuxState,
  resetNuxState,
  toggleHints,
  login,
  logout,
  setMessage,
  needsTutorial,
  resetState,
  submitAnswer,
  addLetter,
  removeLetter,
  sawTutorial,
  resetUserSettings,
  shareLevel,
} = gameSlice.actions;

export default gameSlice.reducer;
