import { GridState } from "./components/Grid"
import { State } from "./storage/daily"
import { GameState } from "./features/game/gameSlice"

export default class GameLogic {
  static isSolved({ marks }: GameState['playerState']) {
    let solved = true

    if (marks && marks.length) {
      for (let c of marks[marks.length - 1]) {
        if (c !== GridState.BULL) {
          solved = false
          break
        }
      }
    } else {
      solved = false
    }
    return solved
  }

  static isLost(state: State, level: GameState['levelState']) {
    const { marks } = state
    return marks && marks.length === level.guesses && !GameLogic.isSolved(state)
  }

  static getMarks(_guess: string, _word: string) {
    const word = Array.from(_word)
    const guess = Array.from(_guess)
    const len = word.length
    const marks = Array.from(Array(len), () => GridState.NONE)

    // Bulls
    for (let i = 0; i < len; i++) {
      if (guess[i] === word[i]) {
        marks[i] = GridState.BULL
        word[i] = '_'
        guess[i] = '_'
      }
    }
    // Cows
    for (let i = 0; i < len; i++) {
      if (guess[i] !== '_') {
        const it = word.indexOf(guess[i])

        if (it >= 0) {
          marks[i] = GridState.COW
          guess[i] = '_'
          word[it] = '_'
        }
      }
    }

    return marks.join('')
  }

}
