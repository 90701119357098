import { Link } from "@imtbl/imx-sdk";
import { ethers } from "ethers";

const SKIP_IMX_IF_POSSIBLE = true;

async function imxFetch(path: string, opts: RequestInit = {}) {
  return fetch(process.env.REACT_APP_IMX_API_URI + path, opts)
    .then((res) => res.json())
}

async function checkIfRegistered(wallet: string) {
  return imxFetch("/v1/users/" + wallet)
    .then((r) => r && r.accounts && r.accounts[0]);
}

export async function getWeb3Wallet(): Promise<string> {
  // attempt to skip link SDK if user is already registered against IMX and has a wallet installed.
  if (SKIP_IMX_IF_POSSIBLE && (window as any).ethereum) {
    const provider = new ethers.providers.Web3Provider(
      (window as any).ethereum
    );
    const accounts = await provider.send("eth_requestAccounts", []);
    if (accounts.length !== 1) {
      throw new Error("Must link 1 and only 1 account.");
    }
    const account = accounts[0];

    const isRegistered = await checkIfRegistered(account);
    if (isRegistered) {
      return account;
    }
  }

  // fall back on link sdk
  const link = new Link(process.env.REACT_APP_IMX_LINK_URI);
  const { address: walletAddress } = await link.setup({});
  console.log("walletAddress", walletAddress);
  return walletAddress;
}
