import differenceInHours from 'date-fns/differenceInHours'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import differenceInSeconds from 'date-fns/differenceInSeconds'

export function getHoursUntil(date) {
  return "" + differenceInHours(date, new Date())
}

export function getMinutesUntil(date) {
  return "" + differenceInMinutes(date, new Date()) % 60;
}

export function getSecondsUntil(date) {
  return "" + differenceInSeconds(date, new Date()) % 60;
}
